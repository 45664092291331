import { edwin_screener_mark } from '~/trades/indicators/edwin/edwin_screener_mark';
import { store } from '../heineken_template/_private/store';
import { edwin_volume } from '~/trades/indicators/edwin/edwin_volume';
import { edwin_macd } from '~/trades/indicators/edwin/edwin_macd';
import { edwin_indicators } from '~/trades/indicators/edwin/edwin_indicators';
import { edwin_evn } from '~/trades/indicators/edwin/edwin_evn';
import { edwin_ma } from '~/trades/indicators/edwin/edwin_ma';
export const edwin_strategiesGroup = {
    main: [
        {
            displayName: '指標',
            indicators: [
                edwin_ma,
                edwin_screener_mark,
                edwin_indicators,
                //edwin_bband,
                edwin_evn,
                //edwin_bband_width,
                edwin_macd,
                edwin_volume,
            ],
            interval: '1D',
            panesRatio: 60,
        },
    ],
};
export const edwin_initStrategies = () => {
    store.charting.indicatorsPreloaded = [
        ...store.charting.indicatorsPreloaded,
        ...[
            edwin_screener_mark,
            edwin_indicators,
            edwin_ma,
            //edwin_bband,
            edwin_evn,
            //edwin_bband_width,
            edwin_macd,
            edwin_volume,
        ],
    ];
    store.charting.initStrategy({
        configs: [...edwin_strategiesGroup.main],
    });
};
